<template>
  <div id="form">
    <el-form
      :ref="formName"
      :model="query"
      :rules="queryRule"
      label-width="120px"
      label-position="left">
      <el-form-item
        align="left"
        label="发送对象"
        prop="target">
        <el-radio-group v-model="query.target">
          <el-radio
            v-for="item in pushTargetList"
            :key="item.value"
            :label="item.value">
            {{ item.label }}
          </el-radio>
        </el-radio-group>
        <div v-if="query.target === 'single'">
          <el-input style="width: 250px;" v-model="username" placeholder="请输入用户名">
            <el-button 
              slot="append"
              type="primary"
              icon="el-icon-search"
              @click="handleUsername" />
          </el-input>
          <div 
            style="padding-top: 10px;"
            v-if="query.target_id && query.target_id !== '0'">
            已綁定：{{ usernameInfo.nickname }} ({{ usernameInfo.username }})
            <el-button 
              @click="handleRemove"
              type="danger">
              移除綁定
            </el-button>
          </div>
          <div v-else>
            目前未綁定任何用戶
          </div>
        </div>
      </el-form-item>
      <el-form-item
        align="left"
        label="消息类型"
        prop="type">
        <el-radio-group v-model="query.type">
          <el-radio
            v-for="item in pushTypeList"
            :key="item.value"
            :label="item.value">
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="标题"
        prop="title">
        <el-input 
          :maxlength="50"
          show-word-limit
          v-model="query.title" />
      </el-form-item>
      <el-form-item
        label="消息内容"
        prop="content">
        <el-input
          maxlength="200"
          show-word-limit
          type="textarea" 
          :autosize="{ minRows: 3, maxRows: 6 }"
          v-model="query.content" />
      </el-form-item>
    </el-form>
    <div>
      <el-button @click="handleCancel">
        取消
      </el-button>
      <el-button
        type="primary"
        :loading="saveLoading"
        @click="handleSubmit">
        确认
      </el-button>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { pushDetail, pushEdit, pushAdd, pushMemberWithUsername, pushMemberWithID, pushAction } from '@/api/admin/push'
import options from '@/utils/options'

export default {
  data () {
    return {
      formName: 'form',
      saveLoading: false,
      pushTypeList: options.pushTypeList,
      pushTargetList: options.pushTargetList,
      username: null,
      usernameInfo: {
        id: null,
        nickname: null,
        username: null,
      },
      query: {
        id: null,
        title: null,
        type: null,
        target_id: null,
        target: null,
        content: null,
      },
      queryRule: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: '请选择消息类型',
            trigger: 'blur'
          }
        ],
        target: [
          {
            required: true,
            message: '请选择发送对象',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入消息内容',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  methods: {
    init () {
      pushDetail(this.query.id).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.query = res.data
            this.handleMemberWithID()
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    handleMemberWithID() {
      if (this.query.target_id === '0') return
      pushMemberWithID(this.query.target_id).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.usernameInfo = res.data
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    handleRemove() {
      this.usernameInfo = null
      this.query.target_id = null
    },
    handleUsername() {
      pushMemberWithUsername(this.username).then(res => {
        if (res.data.length === 0)
          this.$message.error('找不到用户')
        this.usernameInfo = res.data
        this.query.target_id = this.usernameInfo.id
      }).catch(err => {
        this.$message.error(err)
      })
    },
    handleCancel () {
      this.$router.go(-1)
    },
    handleSubmit () {
      this.$refs[this.formName].validate(valid => {
        if (valid)
          this.saveData()
      })
    },
    saveData () {
      this.saveLoading = true
      switch (this.$route.name) {
        case 'AdminMemberPushAdd':
          delete this.query['id']
          pushAdd(this.query).then(res => {
            this.saveLoading = false
            if (res.message.length !== 0)
              this.$message(res.message)
            if (res.status === resStatus.OK) {
              this.handleCancel()
              this.handlePushAction(res.data.id)
            }
          }).catch(err => {
            this.saveLoading = false
            this.$message.error(err)
          })
          break
        case 'AdminMemberPushEdit':
          pushEdit(this.query.id, this.query).then(res => {
            this.saveLoading = false
            if (res.message.length !== 0)
              this.$message(res.message)
            if (res.status === resStatus.OK)
              this.handleCancel()
          }).catch(err => {
            this.saveLoading = false
            this.$message.error(err)
          })
          break
      }
    },
    handlePushAction(id) {
      pushAction(id).then(res => {
      }).catch(err => {
        this.saveLoading = false
        this.$message.error(err)
      })
    },
  },
  created () {
    this.query.id = this.$route.params.id
    if (this.query.id)
      this.init()
  }
}
</script>

<style lang="scss" scoped>
#form{
  max-width: calc((100vw - 200px) * 0.8);
}
</style>
