import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

export function pushAction (id) {
  return request({
    url: `/api/console/push/${id}`,
    method: 'get',
    params: {
      action: 'pushing'
    },
  })
}

export function pushMemberInfo (id) {
  return request({
    url: `/api/console/manage_users/${id}`,
    method: 'get'
  })
}

export function pushMemberWithID (id) {
  return request({
    url: `/api/console/manage_users/${id}`,
    method: 'get'
  })
}

export function pushMemberWithUsername (username) {
  return request({
    url: `/api/console/manage_users`,
    method: 'get',
    params: {
      username: username
    }
  })
}

export function pushList (params) {
  return request({
    url: `/api/console/push`,
    method: 'get',
    params: params
  })
}

export function pushDetail (id) {
  return request({
    url: `/api/console/push/${id}`,
    method: 'get',
  })
}

export function pushAdd (data) {
  return request({
    url: '/api/console/push',
    method: 'post',
    data: getFormData(data)
  })
}
  
export function pushEdit (id, data) {
  return request({
    url: `/api/console/push/${id}`,
    method: 'post',
    data: getFormData(data)
  })
}
